import * as backgroundLeft from "../../assets/images/bespoken_auth_no_text.jpg";
import BespokenLogo from "../../assets/bespoken_logoweb.svg";
import BespokenMountains from "../../assets/bespoken_mountains.svg";
import BinocularsIcon from "../../assets/binoculars.svg";
import * as components from "../components";
import * as firebase from "firebase"
import * as internalAPI from "../services/internal-api";
import * as React from "react";
import * as redux from "react-redux";
import * as reducers from "../reducers";

const style = require("../containers/Login/loginStyles.scss");

/**
 * Configuration objects to pass in to the router when pushing or replacing this page on the router.
 */
export interface SSOConfig {
    /**
     * The next path to go to once logged in.
     */
    nextPathName?: string;
}

interface SSOPageProps {
    onProviderChanged: (email: string) => void;
    provider?: string;
};

interface SSOPageState {
    loggingIn: boolean;
    provider?: string;
}

const mapStateToProps = (state: reducers.State.All) => ({});

const mapDispatchToProps = (dispatch: any) =>
    ({
        // login: function (email: string, password: string, redirectStrat?: SuccessCallback): Promise<User> {
        //     return dispatch(login(email, password, redirectStrat));
        // },
        // setAmazonFlow: function (amazonFlow: boolean): AmazonFlowFlag {
        //     return dispatch(setAmazonFlow(amazonFlow));
        // },
        // setSendEmailFlag: function (sendEmail: boolean): SendEmailFlag {
        //     return dispatch(setLogEmailSentFlag(sendEmail));
        // },
        // signUpWithEmail: function (email: string, password: string, confirmPassword: string, redirectStrat?: SuccessCallback): Promise<User> {
        //     return dispatch(signUpWithEmail(email, password, confirmPassword, redirectStrat));
        // },
        // loginWithGoogle: function (redirectStrat?: SuccessCallback): Promise<User> {
        //     return dispatch(loginWithGoogle(redirectStrat));
        // },
        // resetPassword: function (email: string): Promise<void> {
        //     return dispatch(resetPassword(email));
        // }
    });

export class SSOPage extends React.Component<SSOPageProps, SSOPageState> {
    constructor(props: SSOPageProps) {
        super(props);
        this.state = {
            loggingIn: false,
            provider: props.provider
        };
        this.onProviderChanged = this.onProviderChanged.bind(this)
    }

    async componentDidMount () {

    }

    onProviderChanged (value: string) {
        this.setState(prevState => ({
            ...prevState,
            provider: value,
        }));
    }

    handleFormSubmit = async () => {
        this.setState(prevState => ({
            ...prevState,
            loggingIn: true,
        }))

        let credentials: any
        try {
            const fullProviderName = `saml.${this.state.provider}`
            // @ts-ignore
            const provider = new firebase.auth.SAMLAuthProvider(fullProviderName)
            //const provider = new firebase.auth.SAMLAuthProvider('saml.bac')
            //provider.providerId = 'saml.bac'

            credentials = await firebase.auth().signInWithPopup(provider)
            const tokenPayload = await internalAPI.fetchInternalApi('/users/tokenForSAML', 'POST', credentials,  { "Content-Type": "application/json" }, false)
            if (tokenPayload.authToken) {
                firebase.auth().signInWithCustomToken(tokenPayload.authToken).then(() => {
                })
            } else {
                // @ts-ignore
                const email = credentials.additionalUserInfo.profile['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress']
                    || credentials.additionalUserInfo.profile['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name']
                window.alert('No user exists for: ' + email + '. Please reach out to Bespoken support for assistance.')
                this.setState(prevState => ({...prevState, loggingIn: false }))
            }
        } catch (error) {
            if (credentials) {
                const email = credentials.additionalUserInfo.profile['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress']
                    || credentials.additionalUserInfo.profile['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name']
                window.alert('Error with SAML login - user does not currently exist with email: ' + email + '. Please reach out to Bespoken support for assistance.')

            } else {
                window.alert('Error with SAML login: ' + error.message)
            }
            //this.setState({ error });
            this.setState(prevState => ({...prevState, loggingIn: false }))
        }
    }

    render() {
        const allProps = this.props as any;
        const location = allProps.location;
       // const imageClass = this.state && this.state.bannerHtml ? "" : "no_image";
        return (
            <div>
                <components.LeftPanel className={style.leftPanel} fullHeight={true} image={backgroundLeft}>
                    <div data-id="details">
                        <BinocularsIcon data-id="icon" />
                        <div data-id="message1">Welcome to your Dashboard</div>
                        <div data-id="message2">from here, you can</div>
                        <div data-id="message3">see everything</div>
                    </div>
                    <div data-id="companyName">Bespoken</div>
                </components.LeftPanel>
                <components.RightPanel className={style.rightPanel} fullHeight>
                    <section>
                        <BespokenLogo data-id="icon" style={{paddingBottom: '20px', marginBottom: '0px'}}/>
                        {/* <div style={{fontWeight: 'bold', fontSize: '20pt', color: 'black', paddingBottom: '10px'}}>SSO Login</div> */}
                        <components.Input
                            className={"email_text_id"}
                            label="SSO Provider"
                            onChange={this.onProviderChanged}
                            type="text"
                            value={this.props.provider}
                        />
                        <div style={{paddingBottom: '10px'}}>&nbsp; </div>
                        <components.Button className={` login_email_id`}
                            label="Login via SSO Provider"
                            accent
                            disabled={this.state.loggingIn}
                            //icon={<BespokenLockIcon />}
                            onClick={this.handleFormSubmit}
                            />

                    </section>
                    <BespokenMountains data-id="mountains" />
                </components.RightPanel>
            </div>

        );
    }
};

export default redux.connect(
    mapStateToProps,
    mapDispatchToProps
)(SSOPage);
